@import 'colors';
header {
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 9999;

    .menu {
        flex-grow: 1;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $secondary;
        color:$dark;
        text-decoration: none;
        width: 100%;
        label {
            font-weight: 700;
            color:inherit;
            font-size: 18px;
            pointer-events: none;;
        }
        svg {
            margin-left: 10px;
        }
        .icon{
            margin-left: 10px;
            width:20px;
            height:20px;
            &.lg {
                width:24px;
                height:24px;
            }
        }
        &.active {
            background: $primary-500;
            color:$dark;
            label {

                color:inherit;
               
            }
        }
    }

    .more-menu {
        min-width: 160px;
        flex-grow: 0;
        height: 68px;
        flex-shrink: 0;
        cursor: pointer;

        ul {
            max-height:0;
            overflow:hidden;
            transition: all .3s cubic-bezier(.05, .91, .39, .96);
            li {
                border-top: 1px solid #383a3d;
                .menu {
                    height:52px;
                    label{
                        min-width: 100px;
                    }
                }
            }
            &.show {
                
                    max-height: 400px;
            
            }
        }
        
    }
}