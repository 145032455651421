@import 'colors';
.base-circle{
    stroke: $primary-500;
    fill: transparent;
}
.progress{
    stroke: $primary-800;
    fill: transparent;
    stroke-linecap: round;
    transition: stroke-dashoffset 1s ease-out, stroke 1s ease-out;
}
.counter{
    font-size: 100px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: bold;
    fill: black;
    text-anchor: middle;
    alignment-baseline: middle;
}
.label{
    font-family: "Nunito Sans", sans-serif;
    font-weight: bold;
    fill: black;
    text-anchor: middle;
    alignment-baseline: middle;
    font-size: 25px;
    text-transform: uppercase;
}
.track-pointer{
    r: 6px;
    fill: white;
}
.track-pointer-info{
    r: 25px;
    stroke-width: 3px;
    stroke: white;
    fill: $primary-800;
}
.track-pointer-info-text{
    text-anchor: middle;
    alignment-baseline: middle;
    stroke-width: 0px;
    font-size: 20px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: bold;
    fill: white;
}

