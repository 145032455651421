.store {
    color:white;
    text-align: center;
    color: #fff;
    width: 100%;
    max-width: 1000px;
    padding: 0 12px;
    z-index: 99;
    .logo {
        display:flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
        }
    }

    h1 {
        font-size: 62px;
        font-weight: bold;
        margin: 0;
        line-height: 1.2;
    }

    .note {
        margin-top: 24px;
    }
    
}
.powered-by {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px auto;

    span {
        text-align: center;
        padding: 3px 12px;
        font-size: 14px;
        color: #000000;
        border-radius: 25px;
        background: rgba(255,255,255,0.2);
    }
}



.searchbar {
        display: flex;
        width: 100%;
        align-items: center;
        position: relative;
        border-radius: 10px;
        margin: 24px auto;
        .phoneNumber {
            flex-grow: 1;
        }

        button {
            position: absolute;
            right: 0;
            top: 0;
            width: 80px;
            height: 80px;
            background: $primary-800;
            border: 0;
            border-radius: 0 10px 10px 0;
            cursor: pointer;
            &[disabled]{
                background: #626969;
                cursor: default;
            }
            >* {
                width:36px;
                height:36px;
                color:white;
            }
        }
        .iti__country-list--dropup{color:#222}

        .iti.iti--allow-dropdown {
            width: 100%;
            .mobile {
                font-size: 29px;
                font-family: inherit;
                padding-right: 80px;
                height: 80px;
                background-color: #ffffff;
                border-radius: 10px;
                border: 0;
                width:100%;
                @media (max-width:600px) {
                        height: 64px;
                        font-size: 22px
                    }
                
            }
        }
}


.store-user {
    color:white;
    .container{
        max-width: 800px;
        width:90%;
        padding:0 16px;
        margin:0  auto;
    }
    >.container {
        margin-top: 90px;
    }
    .header {
        padding: 4px 0;
        margin: 0;
        background: #4a4a4a;
        position: relative;
        height:60px;
        top: 0;
        position: fixed;
        width: 100%;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .brand {
            display: flex;
            align-items: center;
            img {
                height: 48px;
                margin-right: 12px;
                border-radius: 10px;
                overflow: hidden;
                background-color: #ffffff;
                width: 48px;
            }
        }

        .home-icon {
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
            text-decoration: none;
            width:36px;
            height: 36px;
            cursor: pointer;
            color:#ffffff;
           
        }

    }
    .stamp-board {
        width: 100%;
        background: $body;
        margin: 0 auto;
        padding: 0 60px;
        border-radius: 10px;
        margin-top: 24px;
        svg {
            width:320px;
            margin:0 auto 24px auto;
            display:block;
        }
    }

    .view-area {
        padding:24px 0;

        .card {
            width:100%;
        }

        h2 {
            background:rgba(255,255,255,0.5);
            border-radius: 5px;
            margin-top: 48px;
            color: #222;
        }
    }

    .new-user {
        display: flex;
        flex-direction: column;
        h3 {
            font-size: 26px;
            font-weight: 500;
            margin: 10px  0;
            text-align: center;
            margin-top: 24px;
        }
        button,.button {
            border: 0;
            padding: 10px 25px;
            height: 48px;
            min-width: 200px;
            border-radius: 5px;
            font-size: 18px;
            font-weight: 500;
            color: #fff;
            background: #1a96e2;
            box-shadow: 0 5px 10px 0 rgba(57, 59, 60 , 0.54);
            display: inline;
            cursor: pointer;
            margin: 24px auto;
            text-decoration: none;
            text-align: center;

        }
    }
}

.privacy-policy {
    color: inherit;
    line-height: 14px;
    font-size: 14px;
    /* text-decoration: none; */
    margin-top: 22px;
    display: block;
    &:hover {
        text-decoration: underline;
        //color: rgb(207, 57, 11);
    }
}

.lifetime-stamps {
    text-transform: uppercase;
    color: #222;
    text-align: center;
    font-weight: 800;
    margin: -20px 0 0 0;
    padding-bottom: 30px;
    font-size: 24px;
}