@import 'colors';
.user-finder {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;

   
    .iti.iti--allow-dropdown {
        width: 100%;
        border-radius: 50px;
        .mobile {
            font-size: 29px;
            border: 2px solid $body;
            background: $body;
            width:100%;
            height:100px;
            border-radius: 50px;
            padding-right: 52px;
            text-align: center;
        }
    }
    .totalStamp {
        position: absolute;
        z-index: 98;
        right: 0;
        margin: 24px;
        width: 52px;
        height: 52px;
        background: $primary-900;
        color: white;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-weight: 800;
        font-size: 20px;
        top: 0;
        box-shadow: 0 0 5px rgba(0, 0 ,0 , 0.3);
        display:flex;
        align-items: center;
        justify-content: center;
    }
}

.life-time-stamp {
    position:absolute;
    bottom:0;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}